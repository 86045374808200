@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700;800&display=swap');

:root {
  --navbar-height: 6vh;
  --content-margin-top: 1vh;
  --content-height: calc(100vh - var(--navbar-height) - var(--content-margin-top));
  --section-header-height: 4vh;
  --section-footer-height: 4vh;
  --section-height: calc(var(--content-height) - 0vh);
  --sub-section-height: calc((var(--section-height) - var(--section-header-height) - var(--section-footer-height) - 2px) / 2);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.custom-dialog::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px) brightness(190%);
  pointer-events: none;
}

.range-bar {
  width: 32px;
  height: 1px;
  background-color: #ccc;
  margin: 0 16px;
}

.switch {
  width: 36px !important;
  height: 20px !important;
  padding: 0 !important;
  margin-right: 8px;

  .MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;

    &.Mui-checked {
      transform: translateX(16px);
      color: #fff;

      &+.MuiSwitch-track {
        background-color: #D1A617 ;
        opacity: 1;
        border: 0;
      }

      ;
    }
  }

  ;

  .MuiSwitch-thumb {
    box-sizing: 'border-box';
    width: 16px;
    height: 16px;
    box-shadow: none;
    background-color: white;
  }

  ;

  .MuiSwitch-track {
    border-radius: 9px;
    background-color: #EAECF0;
    opacity: 1;
  }
}

/* styles.css */
.logo {
  width: 150px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-bottom: 20px;
  /* Add margin below the logo */
}


.button {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 56px;
  background-color: #1A3C7F !important;
  border-radius: 8px !important;
  box-shadow: none !important;

  &:hover {
    background-color: #1A3C7F;
  }
  &:disabled {
    background-color: #8FA4CC !important;
  }
}



.button-highlight {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 56px;
  background-color: #AE8A13 !important;
  border-radius: 8px !important;
  box-shadow: none !important;

  &:hover {
    background-color: #AE8A13;

  }
}

.login-page {
  background-image: url('../public/public/background/mesh-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  /* Adjust the height as needed */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .login-container {
    margin-right: 132px;
    height: 630px;
    width: 465px;
    background: white;
    padding: 48px;
    border-radius: 16px;
    align-self: center;
  }
}


.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .line {
    flex-grow: 1;
        height: 1px;
        background-color: #EAECF0; 
  }
}

.result-container {
  display: flex;
  border: 1px solid #EAECF0;
  border-radius: 3px;
  padding-top: 24px;
  padding-right: 16px;
  padding-left: 16px;
}

.plot-title {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
}

.page-container {
  margin-left: 32px;
  margin-right: 32px;
}

.row-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}


.search-results-container {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-result-container {
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.2s ease;
}

.search-result-container:hover {
  background-color: #f0f0f0;
}